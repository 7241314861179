import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContentWrapper from "../../components/Shared/ContentWrapper"
import ContentSection from "../../components/Shared/ContentSection"
import BlogNav from "../../components/Blog/BlogNav"
import BlogPostMedia from "../../components/Blog/BlogPostMediaPreview"
import ThesisProjectNavigation from "../../components/ThesisProject/ThesisProjectNavigation"
import PostAuthorSection from "../../components/Shared/PostAuthorSection"

const OcadUndergradThesisPage3 = ({ data, uri }) => {
  const postData = {
    id: "ocadutp01",
    postType: "Default",
    title: "Orrery Design: The Orrery",
    slug: "ocad-undergrad-thesis/orrery",
    createdAt: "Jan 10, 2020",
    categories: [
      {
        name: "Coding",
        slug: "coding",
        id: "3YN1fgeDhbqNHSGzcUZuUE",
      },
      {
        name: "Web",
        slug: "web",
        id: "6rlTGOLIAdgMKRsTS5zPaP",
      },
      {
        name: "Mobile",
        slug: "mobile",
        id: "3GUFF463ocF1ZISKLUu2l",
      },
    ],
    featuredImage: {
      file: {
        url: "https://images.ctfassets.net/z5m1dcunobst/YBJQZKR0UMXmLWyuILuoz/181dc25335d4d876309a3db2660bdd17/Orreretically_Speaking_4_16_2020_5_46_24_AM.png",
      },
    },
  }

  return (
    <Layout
      title={postData.title}
      scrollImage={postData.featuredImage.file.url}
    >
      <SEO title={postData.title} />

      <ContentWrapper
        nav={
          <BlogNav
            backButton={
              <Link to="/blog" className="ajax back-to-home-btn">
                <span>Back to Blog</span>
              </Link>
            }
          />
        }
      >
        <ContentSection
          id="sec1"
          // description={`by Jerez Bain on ${postData.createdAt}`}
          decoratorPosition="top"
          separator
          sectionClasses="hidden-section bot-element text-left"
        >
          <div className="section-title fl-wrap post-title">
            <h3>{postData.title}</h3>
            <div className="post-header">
              <a href="#">{postData.createdAt}</a>
              <span>Category : </span>
              {postData.categories.map(category => (
                <Link
                  key={category.id}
                  to={`/blog/categories/${category.slug}`}
                >
                  {category.name}
                </Link>
              ))}
            </div>
          </div>

          <BlogPostMedia post={postData} />

          <ThesisProjectNavigation />

          <div className="post-block fl-wrap">
            <div className="post-opt fl-wrap">
              <ul>
                <li>
                  <a href="#">
                    <i className="fal fa-user" /> Jerez Bain
                  </a>
                </li>
                {/*<li>
                  <a href="#">
                    <i className="fal fa-comments-alt" />{" "}
                    <CommentCount config={disqusConfig} placeholder={"..."} />
                  </a>
                </li>*/}
                {/*<li>
                  <span>
                    <i className="fal fa-eye" /> 123 view
                  </span>
                </li>*/}
              </ul>
            </div>
            <div>
              <div className="row">
                <div className="col">
                  <p className="small-text text-center">
                    <small>(Heliocentric Solar System Model)</small>
                  </p>

                  <p className="text-center">
                    Similar to{" "}
                    <Link to="/ocad-undergrad-thesis/interface">
                      the interface
                    </Link>
                    , the orrery has gone through iterations of design and
                    development.
                  </p>

                  <p className="text-center">
                    Primarily, there are two major iterations with slight
                    variations as the project progressed. When designing my the
                    first iteration, the main question I wanted to address was,{" "}
                    <b>
                      "How can I make the planets operate independent of each
                      other?"
                    </b>
                  </p>
                  <p className="text-center">
                    This lead to several questions that I wanted to address:
                  </p>

                  <div className="row pb-4">
                    <div className="col">
                      <ul className="list-group">
                        <li className="list-group-item">
                          How can I power them independently?
                        </li>
                        <li className="list-group-item">
                          What mechanism will I use to control the movement?
                        </li>
                        <li className="list-group-item">
                          Can I scale it in size for use in a range of
                          installations?
                        </li>
                        <li className="list-group-item">
                          Should it be suspended from the ceiling or stand up?
                        </li>
                      </ul>
                    </div>
                    <div className="col">
                      <ul className="list-group">
                        <li className="list-group-item">
                          How many micro-controllers will I need if they operate
                          in isolation?
                        </li>
                        <li className="list-group-item">
                          Can it be modular, adding planets later?
                        </li>
                        <li className="list-group-item">
                          How can I ensure precision while
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row pt-4">
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/2x154vjCFe6rlde0Q5WRlh/3b128b624e993084fdba3d072b66fa4d/Track_2_2019-Oct-25_07-36-48PM-000_CustomizedView31866336068_png__1_.png"
                    alt=""
                  />
                </div>
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/3VpSesYD3V0cpvSOEvFYLp/2ef9a4763204f172684430ff280cdfa3/SphereAssy_v2.png"
                    alt=""
                  />
                </div>
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/6GU3YXDMbrFKu4oB5EwsHY/b0eefe35b92e3cad72d7d468a82ff5de/overview.png"
                    alt=""
                  />
                </div>
                <div className="col">
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/65dpAAD1EUnhmrrgeanrZZ/d97a396d1bfbfd3f7513adfaf9165382/Suspended_Assembly_Rev2_2020-Jan-24_04-53-53AM-000_CustomizedView17158298050_png__1_.png"
                    alt=""
                  />
                </div>
              </div>

              <div className="row py-4">
                <div className="col">
                  <h2>Version 1</h2>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <img
                    src="https://images.ctfassets.net/z5m1dcunobst/2FQJa6SA5o3r43VgqliJjF/34be04275ac48382141e7ca4562e94ce/Track_2_2019-Oct-25_07-36-48PM-000_CustomizedView31866336068_png__2_.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col">
                  <p>
                    In this iteration I focused mainly on modular design
                    principles where each planet could operate as a separate
                    part of the whole installation.
                  </p>

                  <p>
                    The design consisted of multiple rings increasing in
                    diameter outward. Each ring was made up of a flat wooden
                    base that supported a circular track. These tracks would
                    then hold carriages similar to those used in manufacturing
                    and storage facilities to move packages around. I also took
                    inspiration from panning camera track systems.
                  </p>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col">
                  <iframe
                    width="464"
                    height="261"
                    src="https://www.youtube.com/embed/4X928Hn_Pcw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="col">
                  <h3>If at first you don't succeed...</h3>

                  <p>
                    While creating a functional carriage that rode along the
                    tracks, I noticed that it move unbearably slow. Addressing
                    this issue I attempted at making an improved carriage that
                    sported NEMA 11 stepper motors. It worked well when powered
                    directly from the power supply. However, a looming problem
                    that I had not found a solution to was powering the stepper
                    motors while they were moving. I researched Slot Car tracks
                    and figured out how to construct the a system as you can see
                    the copper tape used on the ring in the video. This led to a
                    fire hazard because powering multiple NEMA 11 motors
                    required far more power than that circuit could facilitate.
                    This led me to scrapping the design and starting over.
                  </p>
                </div>
              </div>

              <hr />

              <div className="row py-4">
                <div className="col">
                  <h2 className="text-center">Version 2 - Physical Orrery</h2>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <img
                    className="img-fluid pb-2"
                    src="https://images.ctfassets.net/z5m1dcunobst/3xr1lsxqIuwVe3SwQEBkEA/be7fe9124d70a60a299d91b871031ca9/Main_Assembly_Concept_2_2020-Apr-18_12-17-22AM-000_CustomizedView12076052588_png__1_.png"
                    alt=""
                  />
                  <p>
                    I decided to scrap the approach of modular design and
                    consider a way to build a single core structure to support
                    all motors near the center of the installation while
                    allowing the planets to move freely. Multiple sketches led
                    me to a design that had all motors in one place. The central
                    shaft would need to be a tube allowing me to pass wires
                    through to power the structure. Then the planets would sit
                    on arms that could freely rotate around the central tubing
                    while supporting the wait of the planet (or astronomical
                    symbol).
                  </p>
                </div>
                <div className="col">
                  <img
                    className="img-fluid pb-2"
                    src="https://images.ctfassets.net/z5m1dcunobst/3WTlK6Rvw75MwNeVf7WDY6/9c83d299f9688ba28ee8999328726aa1/8-arm_Assembly_Suspended_2020-Apr-07_12-55-15AM-000_CustomizedView3982943857_png_1_.png"
                    alt=""
                  />
                  <p>
                    As I improved the design I added lampshade-like covers to
                    conceal the motors, providing a more organic and aesthetic
                    structure. The covers do not impede the rotation of the
                    mechanical parts. This structure allows the orrery to
                    operate in a suspended or standing configuration.
                  </p>
                </div>
              </div>
              <div className="row pb-4">
                <div className="col">
                  <iframe
                    width="464"
                    height="261"
                    src="https://www.youtube.com/embed/a8544R24Q74"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
                <div className="col">
                  <p>
                    In the video you can see the physically constructed orrery.
                    Due to these unprecedented times in 2020 I was not able to
                    complete and showcase this as my thesis project. This lead
                    me to building the art installation in unity. The upside of
                    this alternative is that I had created all the design files
                    necessary to make the virtual experience. I hope to complete
                    the physical installation moving forward.
                  </p>
                </div>
              </div>

              <div className="row pb-4">
                <div className="col">
                  <h2 className="text-center pb-4">
                    Version 2 - Virtual Orrery
                  </h2>
                  <p>
                    This format of this iteration has given me the opportunity
                    to see how i could assemble and setup the installation in a
                    showroom space. My intent was to showcase how it could look
                    in an open gallery or museum space that would allow visitors
                    to experience and interact with the installation.
                  </p>
                  <img
                    className="img-fluid"
                    src="https://images.ctfassets.net/z5m1dcunobst/NSmdn3QLl2UA8KwFWBn2d/e0a9837ff2a8775fce1153b9863a7bff/Orrery_Full_View_01_2_.png"
                    alt="Virtual Orrery Full View"
                  />
                  <p>
                    Please check out the{" "}
                    <Link to="/ocad-undergrad-thesis">main page</Link> to see
                    how you can see the virtual installation in action!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <PostAuthorSection />
        </ContentSection>
      </ContentWrapper>
    </Layout>
  )
}

export default OcadUndergradThesisPage3
